var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "cancellation-reasons",
            "hide-footer": "",
            title: "Reason for cancellation",
            size: _vm.size,
          },
        },
        [
          _c(
            "b-form",
            { on: { submit: _vm.cancellationFeedback } },
            [
              _c(
                "b-form-group",
                [
                  _c("label", { staticClass: "label-sm" }, [
                    _vm._v(
                      "Why are you canceling your services with us today?"
                    ),
                  ]),
                  _c(
                    "b-form-select",
                    {
                      staticClass: "form-control",
                      attrs: { required: "" },
                      model: {
                        value: _vm.feedBack,
                        callback: function ($$v) {
                          _vm.feedBack = $$v
                        },
                        expression: "feedBack",
                      },
                    },
                    _vm._l(_vm.cancellationOptions, function (option) {
                      return _c(
                        "option",
                        {
                          key: option.cancellation_reason,
                          domProps: { value: option.id },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(option.cancellation_reason) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm.isOtherOption
                    ? _c(
                        "div",
                        [
                          _c("label", { staticClass: "label-sm mt-3" }, [
                            _vm._v("Please explain in 100 characters or less"),
                          ]),
                          _c("b-form-textarea", {
                            attrs: {
                              id: "textarea",
                              placeholder: "Enter something...",
                              rows: "3",
                              "max-rows": "6",
                              required: "",
                              maxlength: "100",
                            },
                            model: {
                              value: _vm.feedBackIfOther,
                              callback: function ($$v) {
                                _vm.feedBackIfOther = $$v
                              },
                              expression: "feedBackIfOther",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.servicesIncludesVirtualOffice
                    ? _c("div", [
                        _c(
                          "label",
                          { staticClass: "label-sm mt-3 text-danger" },
                          [
                            _vm._v(
                              "Warning: Cancelling a Virtual Office service will cancel associated Virtual Phone services."
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4 submit-btn-width",
                  attrs: {
                    variant: "danger",
                    disabled: !_vm.feedBack,
                    type: "submit",
                  },
                },
                [_vm._v("\n        Submit\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "cancel-services",
            "hide-footer": "",
            title: "Service Cancellation Agreement",
            size: _vm.size,
          },
        },
        [
          _vm.outstandingBalance > 0
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      "\n        You are cancelling your subscription. We charge by the day and your pro-rated amount is:\n      "
                    ),
                  ]),
                  _c("p", [
                    _vm._v("Balance Due: " + _vm._s(_vm.outstandingBalance)),
                  ]),
                  _c("select-payable", {
                    attrs: { "invoice-view": false },
                    on: { payableInfo: _vm.payableInfo },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.activeFilings.length > 0
            ? _c(
                "div",
                [
                  _c("p", [
                    _c("strong", [_vm._v("Active Filings")]),
                    _c("br"),
                    _vm._v(
                      "\n        The following filings are affected by cancelling these services. Select ones you would like to cancel.\n      "
                    ),
                  ]),
                  _c("ct-table", {
                    ref: "activeFilingsTable",
                    attrs: {
                      items: _vm.activeFilings,
                      fields: _vm.filingFields,
                      loaded: _vm.filingsLoaded,
                      "selectable-function": _vm.selectableFunction,
                      selectable: true,
                      "select-mode": "multi",
                      "is-paginated": false,
                    },
                    on: { "selection-changed": _vm.selectionEvent },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("service-cancellation-text", {
            attrs: { services: _vm.selectedServices },
          }),
          _c("strong", [_vm._v("Representative")]),
          _c("p", { staticClass: "m-b-lg" }, [
            _c("i", [
              _vm._v(
                "\n        By typing your name, you are agreeing to the end of service agreement. You are agreeing this\n        is the equivalent of a traditional signature. This will cancel the selected services with\n        us.\n      "
              ),
            ]),
          ]),
          _c(
            "b-form-group",
            [
              _c("label", { staticClass: "label-lg" }, [
                _vm._v("* Enter your name to agree"),
              ]),
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: { type: "text", required: "" },
                model: {
                  value: _vm.signature,
                  callback: function ($$v) {
                    _vm.signature = $$v
                  },
                  expression: "signature",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mt-4 submit-btn-width",
                  attrs: {
                    variant: "danger",
                    disabled: _vm.submitBtnDisabled || _vm.loading,
                  },
                  on: { click: _vm.cancelServices },
                },
                [
                  _vm.loading
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _c("div", [
                        _vm.selection.length > 0
                          ? _c("div", [
                              _vm._v(
                                "\n            I agree, cancel my services and filings\n          "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                "\n            I agree, cancel my services\n          "
                              ),
                            ]),
                      ]),
                ],
                1
              ),
              _vm.outstandingBalance > 0
                ? _c(
                    "b-button",
                    {
                      staticClass: "mt-4 submit-btn-width ml-3",
                      attrs: {
                        variant: "dark",
                        disabled: _vm.withoutPayingBtnDisabled || _vm.loading,
                      },
                      on: { click: _vm.cancelServicesWithoutPaying },
                    },
                    [
                      _vm.loading
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _c("div", [
                            _vm._v(
                              "\n          I agree, cancel without paying\n        "
                            ),
                          ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }